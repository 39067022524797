import { createAsyncThunk } from '@reduxjs/toolkit';
import { SearchResponse } from '../../commons/Types';
import { LogFilter } from './LogDto';
import { Log } from '../domain/Log';
import { LogService } from './LogService';

export const searchLogsThunk = createAsyncThunk(
    'log/search',
    async (filters: LogFilter): Promise<SearchResponse<Log>> => LogService.searchLogsAsync(filters)
);
