import { Partner } from '../domain/entities/Partner';
import { getRequest } from 'src/config/http';

export abstract class PartnerService {
    public static async findPartnerByIdAsync(partnerId: string): Promise<Partner> {
        return Partner.fromJson(await getRequest(`/partners/${partnerId}`));
    }

    public static async findPartnerBySlugAsync(slug: string): Promise<Partner> {
        return Partner.fromJson(await getRequest(`/partner_by_slug/${slug}`));
    }
}