import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import elementReducer from 'src/features/element/data/ElementSlice';
import groupReducer from 'src/features/group/data/GroupSlice';
import partnerReducer from 'src/features/partner/data/PartnerSlice';
import userReducer from 'src/features/user/data/UserSlice';
import alertReducer from 'src/features/alert/data/AlertSlice';
import logReducer from 'src/features/log/data/LogSlice';

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    element: elementReducer,
    group: groupReducer,
    partner: partnerReducer,
    user: userReducer,
    log: logReducer

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
