import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "src/config/store";
import { StateStatus } from "src/features/commons/Entities";
import { Partner } from "../domain/entities/Partner";
import { findPartnerByIdThunk, findPartnerBySlugThunk } from "./PartnerThunk";

interface PartnerState {
    partnerSelected?: Partner;
    status: StateStatus;
}

export const initialState: PartnerState = {
    partnerSelected: undefined,
    status: 'ready'
}

const partnerSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        selectPartner: (state: PartnerState, action: PayloadAction<Partner | undefined>) => {
            state.partnerSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<PartnerState>) => {

        /*
            Find Partner By Id
        */
        builder.addCase(findPartnerByIdThunk.pending, (state: PartnerState) => {
            state.status = 'loading';
        }).addCase(findPartnerByIdThunk.fulfilled, (state: PartnerState, action: PayloadAction<Partner>) => {
            localStorage.setItem('partner_id', action.payload.id);
            state.partnerSelected = action.payload;
            state.status = 'ready';
        }).addCase(findPartnerByIdThunk.rejected, (state: PartnerState) => {
            state.status = 'error';
        });


        /*
            Find Partner By Slug
        */
        builder.addCase(findPartnerBySlugThunk.pending, (state: PartnerState) => {
            state.status = 'loading';
        }).addCase(findPartnerBySlugThunk.fulfilled, (state: PartnerState, action: PayloadAction<Partner>) => {
            localStorage.setItem('partner_id', action.payload.id);
            state.partnerSelected = action.payload;
            state.status = 'ready';
        }).addCase(findPartnerBySlugThunk.rejected, (state: PartnerState) => {
            state.status = 'error';
        });
    }
});

const { selectPartner } = partnerSlice.actions;

const getPartnerSelected = (state: RootState) => state.partner.partnerSelected;
const getStatus = (state: RootState) => state.partner.status;

export {
    selectPartner,

    getPartnerSelected,
    getStatus
}

export default partnerSlice.reducer;