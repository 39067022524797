import { Box } from '@mui/material';
import UsersButton from './Users';
import GroupsButton from './Groups';
import ElementsButton from './Elements';
import LogsButton from './Log';

function HeaderButtons() {
  return (
    <Box
      sx={{
        mr: 1
      }}
    >
      <UsersButton />
      <GroupsButton />
      <ElementsButton />
      <LogsButton />
    </Box>
  );
}

export default HeaderButtons;
