import { lazy } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from 'src/app/hooks/useAuth';
import MainLayout from "src/app/layouts/AccentHeaderLayout";
import { ProtectedRoute } from "./ProtectedRoute";
import { NavigationRoutes } from "./NavigationRoutes";
import { Loader } from "src/app/components/SuspenseLoader";
import { User } from "src/features/commons/Entities";

const AlertsPage = Loader(lazy(() => import('src/app/pages/alerts')));
const ElementsPage = Loader(lazy(() => import('src/app/pages/elements')));
const UsersPage = Loader(lazy(() => import('src/app/pages/users')));
const LoginPage = Loader(lazy(() => import('src/app/pages/login')));
const RecoveryPasswordPage = Loader(lazy(() => import('src/app/pages/recovery_password')));
const UserProfilePage = Loader(lazy(() => import('src/app/pages/user_profile')));
const GroupsPage = Loader(lazy(() => import('src/app/pages/groups')));
const LogsPage = Loader(lazy(() => import('src/app/pages/log')));

const redirectToHome = (user?: User): any => {
    if (!user || user?.status !== 'active') {
        return NavigationRoutes.Login;
    }

    return '/elements/root';
}

const AppNavigation = () => {
    const { user } = useAuth();

    const publicRoutes = [
        <Route
            key={NavigationRoutes.Login}
            path={NavigationRoutes.Login}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={redirectToHome(user)}
                >
                    <LoginPage />
                </ProtectedRoute>
            }
        />,
        <Route
            key={NavigationRoutes.RecoveryPassword}
            path={NavigationRoutes.RecoveryPassword}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={redirectToHome(user)}
                >
                    <RecoveryPasswordPage />
                </ProtectedRoute>
            }
        />,
    ];

    const adminRoutes = [
        <Route key={'loginUserRoutes'} element={user ? <MainLayout /> : undefined}>

            <Route
                key={NavigationRoutes.Alerts}
                path={NavigationRoutes.Alerts}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <AlertsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Elements}
                path={NavigationRoutes.Elements}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <ElementsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Groups}
                path={NavigationRoutes.Groups}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <GroupsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Users}
                path={NavigationRoutes.Users}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <UsersPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Profile}
                path={NavigationRoutes.Profile}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <UserProfilePage />
                    </ProtectedRoute>}
            />

            <Route
                key={NavigationRoutes.Logs}
                path={NavigationRoutes.Logs}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <LogsPage />
                    </ProtectedRoute>
                }
            />
        </Route>
    ];

    const homePath = (
        <Route path="/" element={<Navigate to={redirectToHome(user)} replace />} />
    );

    const unknownPath = (
        <Route path="*" element={<Navigate to={redirectToHome(user)} replace />} />

    );

    return (
        <Routes>
            {homePath}
            {publicRoutes}
            {adminRoutes}
            {unknownPath}
        </Routes >
    );
}

export default AppNavigation;