import { string } from "prop-types";
import { NotArrayError } from "src/app/errors/NotArrayError";
import { Status } from "src/features/commons/Status";
import { Group } from "src/features/group/domain/entities/Group";
import { User } from "src/features/user/domain/entities/User";
import { Rsvp } from "./Rsvp";

export class Element {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        public name: string,
        public groups: Group[],
        readonly parentId: string,
        public rsvp: boolean,
        public status: Status,
        public createdBy: User,
        public fileUrl?: string,
        public viewers?: Rsvp[]
    ) { }

    public static fromJson(json: any): Element {
        return new Element(
            json['id'],
            json['createdAt'],
            json['name'],
            Group.fromJsonArray(json['groups']),
            json['parentId'],
            json['rsvp'],
            Status.fromString(json['status']),
            User.fromJson(json['createdBy']),
            json['fileUrl'],
            json['viewers'] ? Rsvp.fromJsonArray(json['viewers']) : undefined,
        );
    }

    public static fromJsonArray(jsonArray: any): Element[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

    public isFolder(): boolean {
        return this.fileUrl === undefined || this.fileUrl === null;
    }
}