import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/app/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppNavigation from './app/navigation';
import AppInit from './app/components/AppInit';
import 'src/i18n/i18n';
import { useAppDispatch, useAppSelector } from './config/hooks';
import { getPartnerSelected } from './features/partner/data/PartnerSlice';
import { Partner } from './features/partner/domain/entities/Partner';
import { useEffect } from 'react';
import { findPartnerByIdThunk, findPartnerBySlugThunk } from './features/partner/data/PartnerThunk';



function App() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const partner: Partner = useAppSelector(getPartnerSelected);

  localStorage.setItem("userId", auth?.user?.id)

  useEffect(() => {
    const subdomain: string = window.location.host.split('.')[0];
    if (!subdomain.includes('localhost')) {
      dispatch(findPartnerBySlugThunk(subdomain));
    } else {
      dispatch(findPartnerByIdThunk(process.env.REACT_APP_PARTNER_ID));
    }
  }, []);


  if (!partner || !localStorage.getItem('partner_id')) {
    return <></>;
  }

  return (
    <ThemeProvider partnerTheme={partner.theme || 'GreyGooseTheme'}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? <AppNavigation /> : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;