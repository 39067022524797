import { Grid, FormControl, TextField, Button, Dialog, DialogContent, DialogActions, useTheme, Zoom } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogTitleWithIcon from "src/app/components/DialogTitleWithIcon";
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from "src/config/hooks";
import * as Yup from "yup";
import { User } from '../../domain/entities/User';
import { UserService } from '../../data/UserService';
import { updateUserThunk } from '../../data/UserThunk';
import { bool, func } from "prop-types";
import { useAppSelector } from '../../../../config/hooks';
import { getUserSelected } from "../../data/UserSlice";

interface ProfileFormProps {
    open: boolean;

    onClose: () => void;
}

const ProfileForm: FC<ProfileFormProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const user = useAppSelector(getUserSelected);


    const getUserValidations = () => {
        const validations: any = {
            email: Yup.string().email(t('Users.Errors.Email')).required(t('Users.Errors.Email')),
            name: Yup.string().required(t('Users.Errors.Name')),
            phone: Yup.string().required(t('Users.Errors.Phone')),
        }
        return validations
    }

    const userSchema = Yup.object(getUserValidations());

    const launchNotification = (type: string) => {
        if (type.includes('reject')) {
            enqueueSnackbar(
                t('Users.Errors.Manage'),
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    },
                    TransitionComponent: Zoom
                });
        } else {
            enqueueSnackbar(
                t('Users.ManageSuccess'),
                {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    },
                    TransitionComponent: Zoom
                });
            onClose();
        }
    }

    //
    // Gestionamos la subida del form
    //
    const onHandleSubmit = async (values: any, { setErrors }: any) => {
        setLoading(true);

        // PASSWORD VALIDATION
        if (user) {
            if (values.password !== null) {
                if (values.password.length < 6) {
                    setErrors({ password: t('Users.Errors.PasswordLength') });
                    setLoading(false);
                    return;
                }
            }
        }

        // EMAIL VALIDATION
        // Comprobamos que no existe ya en DB una admon. con el mismo email
        // Si estamos editando, puede existir el mismo email si tengo el mismo
        // ID (soy yo mismo)
        try {
            const userByEmail: User = await UserService.findUserByEmailAsync(values.email);
            if (userByEmail && (userByEmail?.id !== user?.id)) {
                setErrors({ email: t('Users.Errors.EmailAlreadyUse') });
                setLoading(false);
                return;
            }
        } catch (_) { };

        dispatch(
            updateUserThunk({
                ...values,
                id: user.id,
            })).then((result) => {
                setLoading(false);
                launchNotification(result.type);
            });

    }

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitleWithIcon
                title={t("Commons.Profile")}
                icon={CloseIcon}
                onClick={onClose}
            />

            <Formik
                initialValues={{
                    email: user?.email ?? '',
                    name: user?.name ?? '',
                    password: null,
                    phone: user?.phone ?? '',
                }}
                validationSchema={userSchema}
                onSubmit={(values, { setErrors }) => {
                    onHandleSubmit(values, { setErrors });
                }}>


                {
                    props => (
                        <form onSubmit={props.handleSubmit}>
                            <DialogContent
                                sx={{ padding: "2%", my: "2%" }}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                key="name"
                                                fullWidth
                                                color="secondary"
                                                label={t('Users.Attributes.Name')}
                                                variant="outlined"
                                                name="name"
                                                {...props.getFieldProps('name')}
                                                error={props.touched.name && props.errors.name !== undefined}
                                                helperText={props.touched.name && props.errors.name}
                                                inputProps={{
                                                    variant: "filled",
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12} md={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                key="email"
                                                fullWidth
                                                color="secondary"
                                                label={t('Users.Attributes.Email')}
                                                variant="outlined"
                                                name="email"
                                                {...props.getFieldProps('email')}
                                                error={props.touched.email && props.errors.email !== undefined}
                                                helperText={props.touched.email && props.errors.email}
                                                inputProps={{
                                                    variant: "filled",
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}  >
                                        <FormControl fullWidth>
                                            <TextField
                                                key="phone"
                                                fullWidth
                                                color="secondary"
                                                label={t('Users.Attributes.Phone')}
                                                variant="outlined"
                                                name="phone"
                                                {...props.getFieldProps('phone')}
                                                error={props.touched.phone && props.errors.phone !== undefined}
                                                helperText={props.touched.phone && props.errors.phone}
                                                inputProps={{
                                                    variant: "filled",
                                                    maxLength: 9
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={12} md={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                key="password"
                                                fullWidth
                                                color="secondary"
                                                label={t('Users.Attributes.Password')}
                                                variant="outlined"
                                                name="password"
                                                {...props.getFieldProps('password')}
                                                error={props.touched.password && props.errors.password !== undefined}
                                                helperText={props.touched.password && props.errors.password}
                                                inputProps={{
                                                    variant: "filled",
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={onClose}
                                    disabled={loading}>
                                    Cerrar
                                </Button>
                                <Button
                                    disabled={loading}
                                    type={"submit"}
                                    sx={{
                                        alignSelf: 'center',
                                        fontWeight: 'normal',
                                        backgroundColor: `${theme.colors.primary.lighter}`,
                                        '&:hover': {
                                            backgroundColor: `${theme.colors.primary.dark}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.primary.main
                                            )}`
                                        }
                                    }}
                                >
                                    {loading ? t('Commons.Saving') : t('Commons.Save')}
                                </Button>

                            </DialogActions>
                        </form>
                    )
                }
            </Formik >
        </Dialog>
    );

}

ProfileForm.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
}

export default ProfileForm;