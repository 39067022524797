import {
  forwardRef,
  Ref,
  useState,
  ReactElement,
  ChangeEvent,
  useCallback,
  FC
} from 'react';
import {
  Box,
  Divider,
  Button,
  CircularProgress,
  InputBase,
  Typography,
  Card,
  Dialog,
  alpha,
  Slide,
  styled,
  useTheme,
  debounce
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import Scrollbar from 'src/app/components/Scrollbar';
import { useAppSelector, useAppDispatch } from '../../../../config/hooks';
import { changeElementSearcher, getStatus } from '../../data/ElementSlice';
import { useEffect } from 'react';
import { Element } from '../../domain/entities/Element';
import { ElementService } from '../../data/ElementService';
import SearchResultItem from './SearchResultItem';
import useAuth from '../../../../app/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const SearchInputWrapperButton = styled(Button)(
  ({ theme }) => `
  font-weight: normal;
  background: ${theme.colors.alpha.white[10]};
  border:  ${alpha(theme.colors.alpha.white[30], 0.2)} solid 1px;

  &:hover {
    background: ${alpha(theme.colors.alpha.white[30], 0.15)};
    border: ${theme.colors.alpha.white[30]} solid 1px;
  }
`
);

interface ElementSearcherProps { }

const ElementSearcher: FC<ElementSearcherProps> = ({ }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<boolean>(false);
  const status = useAppSelector(getStatus);
  const [folders, setFolders] = useState<Element[]>([]);
  const [files, setFiles] = useState<Element[]>([]);

  useEffect(() => {
    if (status === 'ready') {
      setSearchLoading(false);
    }
  }, [status])

  const debouncedSave = useCallback(
    debounce(async (newValue: string) => {
      const elements: Element[] = (await ElementService.searchElementsAsync({
        skip: 0,
        text: newValue,
        userId: user.isAdmin ? undefined : user.id
      })).data;
      setFolders(elements.filter(element => !element.fileUrl));
      setFiles(elements.filter(element => element.fileUrl));
      setSearchLoading(false);
    }, 500), []);

  const submitSearch = async (event): Promise<void> => {
    event.preventDefault();
    setSearchResults(false);
    setSearchLoading(true);
    debouncedSave(event.target.value as string);
    setSearchLoading(false);
    setSearchResults(true);
  };

  const handleSearchChange = async (event: ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();

    if (event.target.value) {
      setSearchResults(false);
      setSearchValue(event.target.value as string);
      setSearchLoading(true);
      debouncedSave(event.target.value as string);
      setSearchResults(true);
    } else {
      setSearchValue('');
      setSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClickElement = (element: Element) => {
    if (user.isAdmin) {
      // Si eres admin, abres el drawer tanto para carpetas como para
      // ficheros
      dispatch(changeElementSearcher(element));
      setOpen(false);
    } else {
      // Si eres usuario, si es carpeta abres la carpeta y si es file 
      // abres el fichero en otra tab.
      if (element.fileUrl) {
        window.open(element.fileUrl, '_blank', 'noopener,noreferrer');;
      } else {
        navigate(`/elements/${element.id}`);
        setOpen(false);
      }
    }
  }

  const Results = () => (
    <Box
      sx={{
        height: 450
      }}
    >
      <Scrollbar>
        {
          folders.length > 0 ?
            <Box px={2} py={1}>
              <Typography
                sx={{
                  py: 1
                }}
                variant="h5"
              >
                Carpetas
              </Typography>
              {folders.map((element) => <SearchResultItem key={element.id} element={element} onClickElement={handleOnClickElement} />)}
            </Box>
            : <></>
        }
        {
          files.length > 0 ?
            <Box px={2} py={1}>
              <Typography
                sx={{
                  py: 1
                }}
                variant="h5"
              >
                Ficheros
              </Typography>
              {files.map((element) => <SearchResultItem key={element.id} element={element} onClickElement={handleOnClickElement} />)}
            </Box>
            : <></>
        }


      </Scrollbar>
    </Box>
  );

  const NoResult = () => (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{
        background: theme.colors.warning.lighter,
        color: theme.colors.warning.main,
        borderRadius: theme.general.borderRadius,
        fontSize: theme.typography.pxToRem(13),
        display: 'flex',
        alignItems: 'center',
        p: 1,
        mx: 2,
        my: 2
      }}
    >
      <ContactSupportTwoToneIcon
        sx={{
          mr: 0.8,
          fontSize: theme.typography.pxToRem(18)
        }}
      />
      {t('Element.SearchNotResult')}
    </Typography>
  );

  return (
    <>
      <SearchInputWrapperButton
        sx={{ width: '100%' }}
        variant="contained"
        disableElevation
        onClick={handleClickOpen}
        startIcon={<SearchTwoToneIcon />}
      >
        {t('Commons.Search')}
      </SearchInputWrapperButton>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <Box>
          <form onSubmit={submitSearch}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center">
                <SearchTwoToneIcon
                  sx={{
                    ml: 2,
                    color: theme.colors.secondary.main
                  }}
                />
                <SearchInputWrapper
                  value={searchValue}
                  onChange={handleSearchChange}
                  autoFocus
                  placeholder={t('Element.Search')}
                  fullWidth
                />
              </Box>
              <Card
                sx={{
                  ml: 'auto',
                  mr: 2,
                  py: 0.5,
                  px: 1,
                  background: theme.colors.alpha.black[10]
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  esc
                </Typography>
              </Card>
            </Box>
          </form>
        </Box>
        <Divider />
        {!searchLoading && (
          <>
            {!searchResults && (
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  background: theme.colors.info.lighter,
                  color: theme.colors.info.main,
                  borderRadius: theme.general.borderRadius,
                  fontSize: theme.typography.pxToRem(13),
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  mx: 2,
                  my: 2
                }}
              >
                <ContactSupportTwoToneIcon
                  sx={{
                    mr: 0.8,
                    fontSize: theme.typography.pxToRem(18)
                  }}
                />
                {t('Element.StartTypingToSearch')}
              </Typography>
            )}
          </>
        )}
        {searchLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 5
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {searchResults ? (files.length > 0 || folders.length > 0 ? <Results /> : <NoResult />) : (
              <></>
            )}
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default ElementSearcher;
