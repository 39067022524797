import { createAsyncThunk } from '@reduxjs/toolkit';
import { Group } from '../domain/entities/Group';
import { CreateGroupDto, UpdateGroupDto } from './GroupDto';
import { GroupService } from './GroupService';
import { SearchResponse } from '../../commons/Types';
import { GroupFilters } from '../domain/entities/GroupFilters';

export const createGroupThunk = createAsyncThunk(
    'group/create',
    async (createGroup: CreateGroupDto): Promise<Group> =>
        GroupService.createGroupAsync(createGroup)
);

export const deleteGroupThunk = createAsyncThunk(
    'group/delete',
    async (groupId: string) => GroupService.deleteGroupAsync(groupId)
);

export const searchGroupsThunk = createAsyncThunk(
    'group/search',
    async (filters: GroupFilters): Promise<SearchResponse<Group>> => GroupService.searchGroupsAsync(filters)
);

export const updateGroupThunk = createAsyncThunk(
    'group/update',
    async (updateGroup: UpdateGroupDto): Promise<Group> =>
        GroupService.updateGroupAsync(updateGroup)
);

export const findGroupByIdThunk = createAsyncThunk(
    'group/find_by_id',
    async (groupId: string): Promise<Group> => GroupService.findGroupByIdAsync(groupId)
);