import { CreateAlertDto, } from './AlertDto';
import { getRequest, postRequest } from 'src/config/http';
import { Alert } from '../domain/entities/Alert';

export abstract class AlertService {
    public static async createAlertAsync(createAlert: CreateAlertDto): Promise<Alert> {
        return Alert.fromJson(await postRequest('/alerts', createAlert));
    }

    public static async searchAlertsAsync(userId: string): Promise<Alert[]> {
        const response: Alert[] = await getRequest(`/users/${userId}/alerts`);
        return Alert.fromJsonArray(response);
    }
}