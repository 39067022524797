import { Role } from "./Role";
import { Status } from "./Status";
import { LoginEntity, User as UserLogin } from '../../../commons/Entities';

export class User implements LoginEntity {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        public email: string,
        public name: string,
        readonly partnerId: string,
        public role: Role,
        public status: Status,
        public surname?: string,
        public phone?: string
    ) { }

    toLoginUser(): UserLogin {
        return {
            id: this.id,
            avatar: 'noavataravailable',
            name: this.name,
            email: this.email,
            role: this.role === Role.Admin ? 'admin' : 'user',
            status: this.status.value,
            isAdmin: this.role === Role.Admin
        };
    }

    static fromJson(json: any): User {
        return new User(
            json['id'],
            json['createdAt'],
            json['email'],
            json['name'],
            json['partnerId'],
            Role.fromString(json['role']),
            Status.fromString(json['status']),
            json['surname'],
            json['phone']
        )
    }

    static fromJsonArray(jsonArray: any): User[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new Error('body is not an array');
    }

    public getFullName(): string {
        if (this.surname) {
            return `${this.name} ${this.surname}`;
        }

        return this.name;
    }

    public getInitials(): string {
        if (this.surname) {
            return `${this.name.charAt(0).toUpperCase()}${this.surname.charAt(0).toUpperCase()}`;
        }

        return `${this.name.charAt(0).toUpperCase()}${this.name.charAt(1).toUpperCase()}`;
    }

    public isAdmin(): boolean {
        return this.role === Role.Admin;
    }

}