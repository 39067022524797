import { alpha, Tooltip, IconButton, styled } from "@mui/material";
import { t } from "i18next";
import GroupsIcon from '@mui/icons-material/Groups';
import { NavigationRoutes } from "src/app/navigation/NavigationRoutes";
import { useNavigate } from "react-router-dom";

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
          margin-left: ${theme.spacing(1)};
          background: ${theme.colors.alpha.trueWhite[10]};
          color: ${theme.colors.alpha.trueWhite[70]};
          padding: 0;
          width: 42px;
          height: 42px;
          border-radius: 100%;
          transition: ${theme.transitions.create(['background', 'color'])};
      
          &.active,
          &:active,
          &:hover {
            background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
            color: ${theme.colors.alpha.trueWhite[100]};
          }
      `
);

const GroupsButton = () => {
    const navigate = useNavigate();
    const goToGroups = () => {
        navigate(NavigationRoutes.Groups);
    }

    return (
        <Tooltip arrow title={t('Groups.Title')} onClick={goToGroups} >
            <IconButtonPrimary color="primary" >
                <GroupsIcon />
            </IconButtonPrimary>
        </Tooltip>
    );
}

export default GroupsButton;