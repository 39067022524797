import { Status } from "src/features/commons/Status";

export class Partner {
    constructor(
        readonly id: string,
        public color: string,
        readonly createdAt: Date,
        public email: string,
        public logoUrl: string,
        public name: string,
        public phone: string,
        public photoUrl: string,
        public slug: string,
        public status: Status,
        public web: string,
        public theme?: string
    ) { }

    static fromJson(json: any): Partner {
        return new Partner(
            json['id'],
            json['color'],
            json['createdAt'],
            json['email'],
            json['logoUrl'],
            json['name'],
            json['phone'],
            json['photoUrl'],
            json['slug'],
            Status.fromString(json['status']),
            json['web'],
            json['theme']
        )
    }
}