import { OverridableComponent } from "@mui/material/OverridableComponent";
import { makeStyles } from "@mui/styles";
import { DialogTitle, IconButton, SvgIconTypeMap, Typography, useTheme } from "@mui/material";


interface DialogTitleWithIconProps {
    readonly title: string,
    readonly icon?: OverridableComponent<SvgIconTypeMap>,
    readonly onClick?: Function
}

const DialogTitleWithIcon = ({ title, icon: TitleIcon, onClick }: DialogTitleWithIconProps) => {
    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        dialogTitle: {
            backgroundColor: `${theme.colors.primary.dark}`,
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        dialogIcon: {
            color: 'white'
        }
    }));
    const classes = useStyles();

    return (
        <DialogTitle className={classes.dialogTitle} sx={{ m: 0, p: 2 }}>
            <Typography variant={"h4"} style={{color: 'white'}}>
            {title}

                </Typography> 
            {
                TitleIcon && onClick
                    ?
                    <IconButton className={classes.dialogIcon} aria-label="all reports" onClick={() => onClick()}>
                        <TitleIcon style={{color: 'white'}} />
                    </IconButton>
                    : <></>
            }

        </DialogTitle>
    );
}

export default DialogTitleWithIcon;
