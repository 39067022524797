import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Group } from "../domain/entities/Group";
import { createGroupThunk, deleteGroupThunk, findGroupByIdThunk, searchGroupsThunk, updateGroupThunk } from './GroupThunk';
import { SearchResponse } from '../../commons/Types';
import { User } from "src/features/user/domain/entities/User";
import { GroupFilters } from "../domain/entities/GroupFilters";

interface GroupState {
    groupAction: Action;
    groups: Group[];
    groupSelected?: Group;
    filter: GroupFilters;
    status: StateStatus;
    totalCount: number;
    groupUsers: User[];
}

export const initialState: GroupState = {
    groupAction: 'none',
    groups: [],
    groupSelected: undefined,
    filter: {
        text: '',
        skip: 0,
        status: undefined,
        take: 10,
        type: 'team'
    },
    status: 'ready',
    totalCount: 0,
    groupUsers: []
}

const groupSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {

        changeGroupAction: (state: GroupState, action: PayloadAction<Action>) => {
            state.groupAction = action.payload;
        },

        changeFilterText: (state: GroupState, action: PayloadAction<string>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                text: action.payload,
                type: undefined
            };
        },

        changeFilterStatus: (state: GroupState, action: PayloadAction<string | null>) => {
            state.filter = {
                ...state.filter,
                status: action.payload
            };
        },

        changeFilterSkip: (state: GroupState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: action.payload
            };
        },

        changeFilterTake: (state: GroupState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                take: action.payload
            };
        },

        selectGroup: (state: GroupState, action: PayloadAction<Group | undefined>) => {
            state.groupSelected = action.payload;
        },

        selectGroupUsers: (state: GroupState, action: PayloadAction<User[]>) => {
            state.groupUsers = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<GroupState>) => {
        const updateGroup = (state: GroupState, action: PayloadAction<Group>) => {
            state.groups = state.groups.map(group => group.id === action.payload.id ? action.payload : group);
            if (state.groupSelected?.id == action.payload.id) {
                state.groupSelected = action.payload;
            }
            state.status = 'ready';
        }

        /*
            Create Group
        */
        builder.addCase(createGroupThunk.pending, (state: GroupState) => {
            state.status = 'loading';
        }).addCase(createGroupThunk.fulfilled, (state: GroupState, action: PayloadAction<Group>) => {
            state.groups.unshift(action.payload);
            state.groupSelected = undefined;
            state.status = 'ready';
        }).addCase(createGroupThunk.rejected, (state: GroupState) => {
            state.status = 'error';
        });

        /*
            Delete Group
        */
        builder.addCase(deleteGroupThunk.pending, (state: GroupState) => {
            state.status = 'loading';
        }).addCase(deleteGroupThunk.fulfilled, (state: GroupState, action: PayloadAction<Group>) => {
            state.status = 'ready';
            state.groups = state.groups.filter(group => group.id !== action.payload.id);
            if (state.groupSelected?.id === action.payload.id) {
                state.groupSelected = undefined
            }
            state.groupAction = 'none';
        }).addCase(deleteGroupThunk.rejected, (state: GroupState) => {
            state.status = 'error';
        });

        /*
            Find Group By Id
        */
        builder.addCase(findGroupByIdThunk.pending, (state: GroupState) => {
            state.status = 'loading';
        }).addCase(findGroupByIdThunk.fulfilled, (state: GroupState, action: PayloadAction<Group>) => {
            state.groupSelected = action.payload;
            state.status = 'ready';
        }).addCase(findGroupByIdThunk.rejected, (state: GroupState) => {
            state.status = 'error';
        });

        /*
             Search Groups
        */
        builder.addCase(searchGroupsThunk.pending, (state: GroupState) => {
            state.status = 'loading';
        }).addCase(searchGroupsThunk.fulfilled, (state: GroupState, action: PayloadAction<SearchResponse<Group>>) => {
            state.groups = action.payload.data;
            state.totalCount = action.payload.totalCount;
            state.status = 'ready';
        }).addCase(searchGroupsThunk.rejected, (state: GroupState) => {
            state.status = 'error';
        });

        /*
            Update Group
        */
        builder.addCase(updateGroupThunk.pending, (state: GroupState) => {
            state.status = 'loading';
        }).addCase(updateGroupThunk.fulfilled, (state: GroupState, action: PayloadAction<Group>) => {
            updateGroup(state, action);
        }).addCase(updateGroupThunk.rejected, (state: GroupState) => {
            state.status = 'error';
        });
    }
});

const { changeGroupAction, changeFilterSkip, changeFilterStatus, changeFilterTake, changeFilterText, selectGroup, selectGroupUsers } = groupSlice.actions;

const getGroupAction = (state: RootState) => state.group.groupAction;
const getGroupFilter = (state: RootState) => state.group.filter;
const getGroups = (state: RootState) => state.group.groups;
const getGroupSelected = (state: RootState) => state.group.groupSelected;
const getGroupUsers = (state: RootState) => state.group.groupUsers;
const getStatus = (state: RootState) => state.group.status;
const getTotalCount = (state: RootState) => state.group.totalCount;

export {
    changeGroupAction,
    changeFilterSkip,
    changeFilterStatus,
    changeFilterTake,
    changeFilterText,
    selectGroup,
    selectGroupUsers,

    getGroupAction,
    getGroupFilter,
    getGroups,
    getGroupSelected,
    getGroupUsers,
    getStatus,
    getTotalCount
}

export default groupSlice.reducer;