import { createAsyncThunk } from '@reduxjs/toolkit';
import { Partner } from '../domain/entities/Partner';
import { PartnerService } from './PartnerService';



export const findPartnerByIdThunk = createAsyncThunk(
    'partner/find_by_id',
    async (partnerId: string): Promise<Partner> => PartnerService.findPartnerByIdAsync(partnerId)
);

export const findPartnerBySlugThunk = createAsyncThunk(
    'partner/find_by_slug',
    async (slug: string): Promise<Partner> => PartnerService.findPartnerBySlugAsync(slug)
);