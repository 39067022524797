import { createAsyncThunk } from '@reduxjs/toolkit';
import { Element } from '../domain/entities/Element';
import { CreateElementDto, UpdateElementDto, CreateRsvpDto, ElementFilter, ElementsByParentIdDto, MoveElementDto } from './ElementDto';
import { ElementService } from './ElementService';
import { SearchResponse } from '../../commons/Types';
import { Rsvp } from '../domain/entities/Rsvp';

export const createRsvpThunk = createAsyncThunk(
    'rsvp/create',
    async (createRsvp: CreateRsvpDto): Promise<Rsvp> =>
        ElementService.createRsvpAsync(createRsvp)
);

export const createElementThunk = createAsyncThunk(
    'element/create',
    async (createElement: CreateElementDto): Promise<Element> =>
        ElementService.createElementAsync(createElement)
);

export const deleteElementThunk = createAsyncThunk(
    'element/delete',
    async (elementId: string) => ElementService.deleteElementAsync(elementId)
);

export const searchParentElementsThunk = createAsyncThunk(
    'element/search_parent',
    async (filters: ElementFilter): Promise<Element[]> => ElementService.searchParentElementsAsync(filters)
);

export const searchElementsThunk = createAsyncThunk(
    'element/search',
    async (filters: ElementFilter): Promise<SearchResponse<Element>> => ElementService.searchElementsAsync(filters)
);

export const updateElementThunk = createAsyncThunk(
    'element/update',
    async (updateElement: UpdateElementDto): Promise<Element> =>
        ElementService.updateElementAsync(updateElement)
);

export const findElementsByParentIdThunk = createAsyncThunk(
    'element/find_by_parent_id',
    async (elementsByParentId: ElementsByParentIdDto): Promise<Element[]> => ElementService.findElementsByParentIdAsync(elementsByParentId)
);

export const findElementByIdThunk = createAsyncThunk(
    'element/find_by_id',
    async (elementId: string): Promise<Element> => ElementService.findElementByIdAsync(elementId)
);

export const moveElementThunk = createAsyncThunk(
    'element/move',
    async (moveElement: MoveElementDto): Promise<Element> =>
        ElementService.moveElementAsync(moveElement)
);