import { getRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
import { Log } from '../domain/Log';
import { LogFilter } from './LogDto';
export abstract class LogService {
    public static async searchLogsAsync(filters: LogFilter): Promise<SearchResponse<Log>> {
        const response: SearchResponse<Log> = await getRequest('/logs', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Log.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }


}