import { CreateUserDto, UpdateUserDto } from './UserDto';
import { User } from '../domain/entities/User';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
import { Filters } from '../../commons/Entities';

export abstract class UserService {

    public static async createUserAsync(createUser: CreateUserDto): Promise<User> {
        return User.fromJson(await postRequest('/users', createUser));
    }

    public static async deleteUserAsync(userId: string): Promise<User> {
        return User.fromJson(await deleteRequest(`/users/${userId}`));
    }

    public static async findUserByEmailAsync(email: string): Promise<User> {
        return User.fromJson(await getRequest(`/users?email=${email}`));
    }

    public static async findUserByIdAsync(userId: string): Promise<User> {
        return User.fromJson(await getRequest(`/users/${userId}`));
    }

    public static async searchUsersAsync(filters: Filters): Promise<SearchResponse<User>> {
        const response: SearchResponse<User> = await getRequest('/users', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: User.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }

    public static async updateUserAsync(updateUser: UpdateUserDto): Promise<User> {
        return User.fromJson(await patchRequest('/users', updateUser));
    }
}