import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { User } from "../domain/entities/User";
import { createUserThunk, deleteUserThunk, findUserByIdThunk, searchUsersThunk, updateUserThunk } from './UserThunk';
import { SearchResponse } from '../../commons/Types';
import { Filters } from '../../commons/Entities';

interface UserState {
    userAction: Action;
    users: User[];
    userSelected?: User;
    filter: Filters;
    status: StateStatus;
    totalCount: number;
}

export const initialState: UserState = {
    userAction: 'none',
    users: [],
    userSelected: undefined,
    filter: {
        text: '',
        skip: 0,
        status: undefined,
        take: 10
    },
    status: 'ready',
    totalCount: 0,
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

        changeUserAction: (state: UserState, action: PayloadAction<Action>) => {
            state.userAction = action.payload;
        },

        changeFilter: (state: UserState, action: PayloadAction<Filters>) => {
            state.filter = action.payload
        },

        changeFilterText: (state: UserState, action: PayloadAction<string>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                text: action.payload
            };
        },

        changeFilterStatus: (state: UserState, action: PayloadAction<string | null>) => {
            state.filter = {
                ...state.filter,
                status: action.payload
            };
        },

        changeFilterSkip: (state: UserState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: action.payload
            };
        },

        changeFilterTake: (state: UserState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                take: action.payload
            };
        },

        selectUser: (state: UserState, action: PayloadAction<User | undefined>) => {
            state.userSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
        const updateUser = (state: UserState, action: PayloadAction<User>) => {
            state.users = state.users.map(user => user.id === action.payload.id ? action.payload : user);
            if (state.userSelected?.id == action.payload.id) {
                state.userSelected = action.payload;
            }
            state.status = 'ready';
        }

        /*
            Create User
        */
        builder.addCase(createUserThunk.pending, (state: UserState) => {
            state.status = 'loading';
        }).addCase(createUserThunk.fulfilled, (state: UserState, action: PayloadAction<User>) => {
            state.users.unshift(action.payload);
            state.userSelected = undefined;
            state.status = 'ready';
        }).addCase(createUserThunk.rejected, (state: UserState) => {
            state.status = 'error';
        });

        /*
            Delete User
        */
        builder.addCase(deleteUserThunk.pending, (state: UserState) => {
            state.status = 'loading';
        }).addCase(deleteUserThunk.fulfilled, (state: UserState, action: PayloadAction<User>) => {
            state.status = 'ready';
            state.users = state.users.filter(user => user.id !== action.payload.id);
            if (state.userSelected?.id === action.payload.id) {
                state.userSelected = undefined
            }
            state.userAction = 'none';
        }).addCase(deleteUserThunk.rejected, (state: UserState) => {
            state.status = 'error';
        });

        /*
            Find User By Id
        */
        builder.addCase(findUserByIdThunk.pending, (state: UserState) => {
            state.status = 'loading';
        }).addCase(findUserByIdThunk.fulfilled, (state: UserState, action: PayloadAction<User>) => {
            state.userSelected = action.payload;
            state.status = 'ready';
        }).addCase(findUserByIdThunk.rejected, (state: UserState) => {
            state.status = 'error';
        });

        /*
             Search Users
        */
        builder.addCase(searchUsersThunk.pending, (state: UserState) => {
            state.status = 'loading';
        }).addCase(searchUsersThunk.fulfilled, (state: UserState, action: PayloadAction<SearchResponse<User>>) => {
            state.users = action.payload.data;
            state.totalCount = action.payload.totalCount;
        }).addCase(searchUsersThunk.rejected, (state: UserState) => {
            state.status = 'error';
        });

        /*
            Update User
        */
        builder.addCase(updateUserThunk.pending, (state: UserState) => {
            state.status = 'loading';
        }).addCase(updateUserThunk.fulfilled, (state: UserState, action: PayloadAction<User>) => {
            updateUser(state, action);
        }).addCase(updateUserThunk.rejected, (state: UserState) => {
            state.status = 'error';
        });
    }
});

const {
    changeUserAction,
    changeFilter,
    changeFilterSkip,
    changeFilterStatus,
    changeFilterTake,
    changeFilterText,
    selectUser
} = userSlice.actions;

const getUserAction = (state: RootState) => state.user.userAction;
const getUserFilter = (state: RootState) => state.user.filter;
const getUsers = (state: RootState) => state.user.users;
const getUserSelected = (state: RootState) => state.user.userSelected;
const getStatus = (state: RootState) => state.user.status;
const getTotalCount = (state: RootState) => state.user.totalCount;

export {
    changeUserAction,
    changeFilter,
    changeFilterSkip,
    changeFilterStatus,
    changeFilterTake,
    changeFilterText,
    selectUser,

    getUserAction,
    getUserFilter,
    getUsers,
    getUserSelected,
    getStatus,
    getTotalCount
}

export default userSlice.reducer;