export class UserLog {
    constructor(
        readonly id: string,
        readonly email: string,
        readonly name: string
    ) { }

    public static fromJson(json: any): UserLog {
        return new UserLog(
            json['id'],
            json['email'],
            json['name'],
        );
    }

}