const esJSON = {

  Alerts: {
    Attributes: {
      Title: 'Título',
      Description: 'Descripción',
      Status: 'Estado',
      CreatedAt: 'Fecha creación',
      responsedAt: 'Fecha respuesta'
    },
    AddNew: 'Nueva alerta',
    Errors: {
      Title: 'Debes introducir un título',
      Description: 'Debes introducir una descripción',
      Manage: 'Se ha producido un error al crear la alerta'
    },
    Title: 'Listado de alertas',
    ManageSuccess: 'Alerta creada correctamente',
    Entity: 'Alertas',
    NoResults: 'No hay alertas creadas para este usuario',
    Summary: 'Listado de alertas asignadas al usuario'
  },

  /* :: COMMONS :: */
  Commons: {

    Actions: 'Acciones',
    Error: 'Se ha producido un error',
    Cancel: 'Cancelar',
    ClickHere: 'clic aquí',
    ConfirmAction: 'Confirmar Acción',
    Confirm: 'Confirmar',
    CreatedAt: 'Creado',
    CreatedBy: 'Autor',

    Delete: 'Eliminar',
    Deleting: 'Eliminando',
    Download: 'Descargar',

    Edit: 'Editar',

    Files: 'Ficheros',

    Gender: {
      Title: 'Género',

      female: 'Mujer',
      male: 'Hombre',
      none: 'Sin indicar'
    },

    Login: {
      Email: 'Email',
      EmailInstructions: 'Se ha enviado un email a tu correo eléctronico con las instrucciones para recuperar tu contraseña',
      EmailRecovery: 'Escribe tu email y te enviaremos un correo eléctronico para recuperar la contraseña',
      Errors: {
        Email: 'Debes introducir un email válido',
        Password: 'Debes introducir una contraseña válida',
        Terms: 'Debes aceptar los téminos y condiciones'
      },
      IAccept: 'Acepto',
      GoToLogin: 'Volver al login',
      LoginError: 'El email o la contraseña es incorrecta',
      LogOut: 'Cerrar sesión',
      LostPassword: 'Recuperar contraseña',
      Password: 'Contraseña',
      RecoverPassword: 'Recuperar contraseña',
      RecoverPassEmailSent: 'Comprueba la bandeja de entrada de tu correo eléctronico.',
      SignIn: 'Autenticarse',
      Subtitle: 'Accede con tu usuario y tu contraseña',
      TermsAndConditions: 'los téminos y condiciones del servicio',
      Title: 'Acceso',
      TrySignIn: 'Para acceder a la plataforma',
    },
    Move: 'Mover',
    Open: 'Abrir',

    PaginationPrep: 'de',
    Permissions: 'Permisos',
    PerPage: '{{resource}} por página',
    Profile: 'Mi datos',
    Role: {
      admin: 'Admin',
      all: 'Todos',
      user: 'Usuario',
      Title: 'Rol'
    },

    Save: 'Guardar',
    Saving: 'Guardando',
    Search: 'Buscar',
    SeeDetails: 'Ver detalles',
    Status: {
      active: 'Activo',
      all: 'Todos',
      inactive: 'Inactivo',
      pending: 'Pendiente',
      refused: 'Rechazada',
      accepted: 'Aceptada',
      Title: 'Estado'
    },

    Upload: 'Subir Fichero',
    UploadBox: {
      BadType: 'No puedes subir ficheros del tipo seleccionado',
      DragAndDrop: 'Arrastra ficheros hasta aquí',
      DragNow: 'Arrastra ficheros para empezar a subir',
      FilesToUpload: 'Has subido',
      Title: 'Subir Ficheros',
      Subtitle: 'Puedes pulsar en el recuadro de abajo o arrastrar hasta aquí los ficheros que quieras subir a la plataforma'
    },
    Uploading: 'Subiendo Fichero...',
  },

  /* :: ELEMENT :: */
  Element: {
    ConfirmRsvp: 'Vas a abrir un fichero que tiene acuse de recibo. Se guardará la fecha de apertura.',
    Created: 'Creado',
    Details: 'Detalles',
    Empty: 'Todavía no tienes ninguna carpeta o fichero. Puedes crear carpetas o subir ficheros mediante los botones situados arriba a la derecha.',
    Errors: {
      Remove: 'Se ha producido un error'
    },
    File: {
      AddNew: 'Subir Ficheros',
      ManageSuccess: 'Ficheros subidos correctamente',
      ManageUpdateSuccess: 'Ficheros actualizados correctamente',
      Members: 'Permisos',
      Name: 'Nombre',
      RsvpMessage: 'Los usuarios deberán aceptar la recepción del fichero antes de poder leerlo',
      Title: 'Ficheros',
      Upload: 'Subir Ficheros',
    },
    Folder: {
      AddNew: 'Nueva Carpeta',
      Delete: 'Eliminar carpeta',
      Edit: 'Editar Carpeta',
      Errors: {
        Name: 'Debes introducir un nombre válido'
      },
      ManageSuccess: 'Carpeta guardada correctamente',
      Name: 'Nombre de la carpeta',
      New: 'Nueva Carpeta',
      NoGroupsSelected: 'Ningún grupo con permisos sobre la carpeta',
      Open: 'Abrir carpeta',
      Permissions: 'Permisos sobre la carpeta',
      SelectGroup: 'Usuario o grupo de usuarios',
      Title: 'Carpetas'
    },
    Permissions: 'Permisos',
    ParentPermissions: 'Permisos heredados',
    NoParentPermissions: 'No hay permisos heredados',
    RemoveSuccess: 'Se ha elminiado correctamente',
    RemoveConfirmText: '¿Estás seguro de borrar esta carpeta?',
    Rsvp: 'Acuse Recibo',
    NoRsvp: 'No hay ningún acuse de recibo',
    StartTypingToSearch: 'Empieza a escribir para buscar ficheros y carpetas',
    Search: 'Buscar...',
    SearchNotResult: 'Ningún fichero ni carpeta encontrado',
    Summary: 'Listado de carpetas y documentos. Lore impsun haciendo clic en nueva carpeta o nuevo fichero',
    Title: 'Mis elementos'
  },

  /* GROUPS */
  Groups: {
    AddNew: 'Nuevo grupo',
    Attributes: {
      Id: 'Identificador',
      CreatedAt: 'Fecha de alta',
      Name: 'Nombre',
      Type: 'Tipo',
      Types: {
        individual: 'Individual',
        team: 'Colectivo'
      },
      TotalMembers: 'Nº Miembros',
      Members: 'Miembros',
      Status: 'Estado',
    },
    Edit: 'Editar grupo',
    Errors: {
      Manage: 'No se ha podido guardar el grupo',
      Name: 'Debe introducir un nombre válido',
      UsersLength: 'Debes añadir al menos un usuario al grupo',
      Status: 'Debe introducir un estado válido',
    },
    ManageUsers: {
      Action: 'Añadir un usuario',
      NoUsers: 'Aún no hay internos en el grupo',
      Title: 'Usuarios en el grupo'
    },
    ManageSuccess: 'Grupo guardado correctamente',
    NoResults: 'No hay resultados',
    Title: 'Grupos',
    Search: 'Buscar por nombre...',
    Summary: 'En esta sección podrás consultar, dar de alta o dar de baja grupos'
  },

  /* USERS */

  Users: {
    AddNew: 'Nuevo usuario',
    Attributes: {
      Id: 'Identificador',
      CreatedAt: 'Fecha de alta',
      Email: 'Email',
      Name: 'Nombre',
      Password: 'Contraseña',
      Role: 'Rol',
      Status: 'Estado',
      Phone: 'Teléfono'
    },
    Edit: 'Editar usuario',
    Errors: {
      Email: 'Debe introducir un email válido',
      Manage: 'No se ha podido guardar el usuario',
      Name: 'Debe introducir un nombre válido',
      Password: 'Escribe una contraseña',
      PasswordLength: 'La contraseña debe tener al menos 6 caracteres',
      Phone: 'Debe introducir un teléfono válido',
      Surname: 'Debe introducir unos apellidos válidos',
      Status: 'Debe introducir un estado válido',
      Role: 'Debe introducir un rol válido',
      EmailAlreadyUse: 'El email ya está en uso',
    },
    ManageSuccess: 'Usuario guardado correctamente',
    NoResults: 'No hay resultados',
    Title: 'Usuarios',
    Search: 'Buscar por nombre, email...',
    Summary: 'En esta sección podrás consultar, dar de alta o dar de baja usuarios'
  }

}

export default esJSON;