import { CreateGroupDto, UpdateGroupDto } from './GroupDto';
import { Group } from '../domain/entities/Group';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
import { GroupFilters } from '../domain/entities/GroupFilters';

export abstract class GroupService {

    public static async createGroupAsync(createGroup: CreateGroupDto): Promise<Group> {
        return Group.fromJson(await postRequest('/groups', createGroup));
    }

    public static async deleteGroupAsync(groupId: string): Promise<Group> {
        return Group.fromJson(await deleteRequest(`/groups/${groupId}`));
    }

    public static async findGroupByIdAsync(groupId: string): Promise<Group> {
        return Group.fromJson(await getRequest(`/groups/${groupId}`));
    }

    public static async searchGroupsAsync(filters: GroupFilters): Promise<SearchResponse<Group>> {
        const response: SearchResponse<Group> = await getRequest('/groups', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Group.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }

    public static async updateGroupAsync(updateGroup: UpdateGroupDto): Promise<Group> {
        return Group.fromJson(await patchRequest('/groups', updateGroup));
    }
}