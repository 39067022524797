export class AlertStatus {
  static readonly Accepted = new AlertStatus('accepted');

  static readonly Refused = new AlertStatus('refused');

  static readonly Pending = new AlertStatus('pending');

  constructor(public readonly value: string) { }

  static getValues(): AlertStatus[] {
    return [this.Accepted, this.Refused, this.Pending];
  }

  static getStringValues(): string[] {
    return ['accepted', 'refused', 'pending']
  }

  static fromString(string: string): AlertStatus {
    const value = this.getValues().find(enumValue => enumValue.value === string)
    if (value) {
      return value;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ∫ does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
      }`
    );
  }
}
