import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCebK2l30mGvt6SUbUXrVnmdNA6gompT9Y",
  authDomain: "gestion-documental-c090d.firebaseapp.com",
  projectId: "gestion-documental-c090d",
  storageBucket: "gestion-documental-c090d.appspot.com",
  messagingSenderId: "921792036946",
  appId: "1:921792036946:web:d97c78beafb6df2d699f8b",
  measurementId: "G-9VLXEQH46H"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

getAnalytics(firebase.apps[0]);


export default firebase;