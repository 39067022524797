import { NotArrayError } from "src/app/errors/NotArrayError";
import { Status } from "src/features/commons/Status";
import { User } from "src/features/user/domain/entities/User";
import { GroupType } from "./GroupType";

export class Group {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        public name: string,
        public status: Status,
        public type: GroupType,
        public users: User[]
    ) { }

    public static fromJson(json: any): Group {
        return new Group(
            json['id'],
            json['createdAt'],
            json['name'],
            Status.fromString(json['status']),
            GroupType.fromString(json['type']),
            json['users'] ? User.fromJsonArray(json['users']) : [],
        );
    }

    public static fromJsonArray(jsonArray: any): Group[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

    public getInitials(): string {
        const groupNameWords: string[] = this.name.split(' ');
        if (groupNameWords.length === 1) {
            return groupNameWords[0].charAt(0).toUpperCase();
        }
        return `${groupNameWords[0].charAt(0).toUpperCase()}${groupNameWords[1].charAt(0).toUpperCase()}`
    }
}