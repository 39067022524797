export class GroupType {

    static readonly Individual = new GroupType('individual');

    static readonly Team = new GroupType('team');

    constructor(public readonly value: string) { }

    static getValues(): GroupType[] {
        return [this.Individual, this.Team, ];
    }

    static getStringValues(): string[] {
        return this.getValues().map(value => value.value);
    }

    static fromString(string: string): GroupType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}