import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Element } from "../domain/entities/Element";
import { createElementThunk, createRsvpThunk, deleteElementThunk, findElementByIdThunk, searchElementsThunk, updateElementThunk, searchParentElementsThunk, findElementsByParentIdThunk, moveElementThunk } from './ElementThunk';
import { SearchResponse } from '../../commons/Types';
import { Filters } from '../../commons/Entities';
import { Rsvp } from '../domain/entities/Rsvp';

interface ElementState {
    elementAction: Action;
    elements: Element[];
    elementSearcher?: Element;
    elementSelected?: Element;
    filter: Filters;
    status: StateStatus;
    totalCount: number;
}

export const initialState: ElementState = {
    elementAction: 'none',
    elements: [],
    elementSearcher: undefined,
    elementSelected: undefined,
    filter: {
        text: '',
        skip: undefined,
        status: undefined,
        take: undefined
    },
    status: 'ready',
    totalCount: 0,
}

const elementSlice = createSlice({
    name: 'elements',
    initialState,
    reducers: {
        changeElementSearcher: (state: ElementState, action: PayloadAction<Element | undefined>) => {
            state.elementSearcher = action.payload;
        },

        changeElementAction: (state: ElementState, action: PayloadAction<Action>) => {
            state.elementAction = action.payload;
        },

        changeFilterText: (state: ElementState, action: PayloadAction<string>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                text: action.payload
            };
        },

        changeFilterStatus: (state: ElementState, action: PayloadAction<string | null>) => {
            state.filter = {
                ...state.filter,
                status: action.payload
            };
        },

        changeFilterSkip: (state: ElementState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: action.payload
            };
        },

        changeFilterTake: (state: ElementState, action: PayloadAction<number>) => {
            state.filter = {
                ...state.filter,
                skip: 0,
                take: action.payload
            };
        },

        selectElement: (state: ElementState, action: PayloadAction<Element | undefined>) => {
            state.elementSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<ElementState>) => {
        const updateElement = (state: ElementState, action: PayloadAction<Element>) => {
            state.elements = state.elements.map(element => element.id === action.payload.id ? action.payload : element);
            if (state.elementSelected?.id == action.payload.id) {
                state.elementSelected = action.payload;
            }
            state.status = 'ready';
        }

        /*
            Create Rsvp
        */
        builder.addCase(createRsvpThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(createRsvpThunk.fulfilled, (state: ElementState, action: PayloadAction<Rsvp>) => {
            state.status = 'ready';
        }).addCase(createRsvpThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Create Element
        */
        builder.addCase(createElementThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(createElementThunk.fulfilled, (state: ElementState, action: PayloadAction<Element>) => {
            state.elements.unshift(action.payload);
            state.status = 'ready';
        }).addCase(createElementThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Delete Element
        */
        builder.addCase(deleteElementThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(deleteElementThunk.fulfilled, (state: ElementState, action: PayloadAction<Element>) => {
            state.status = 'ready';
            state.elements = state.elements.filter(element => element.id !== action.payload.id);
            if (state.elementSelected?.id === action.payload.id) {
                state.elementSelected = undefined
            }
            state.elementAction = 'none';
        }).addCase(deleteElementThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Find Element By Id
        */
        builder.addCase(findElementByIdThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(findElementByIdThunk.fulfilled, (state: ElementState, action: PayloadAction<Element>) => {
            state.elementSelected = action.payload;
            state.status = 'ready';
        }).addCase(findElementByIdThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Move Element
        */
        builder.addCase(moveElementThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(moveElementThunk.fulfilled, (state: ElementState, action: PayloadAction<Element>) => {
            state.status = 'ready';
            state.elements = state.elements.filter(element => element.id !== action.payload.id);
            if (state.elementSelected?.id === action.payload.id) {
                state.elementSelected = undefined
            }
            state.elementAction = 'none';
        }).addCase(moveElementThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Search Parent Elements
        */
        builder.addCase(searchParentElementsThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(searchParentElementsThunk.fulfilled, (state: ElementState, action: PayloadAction<Element[]>) => {
            state.elements = action.payload;
            state.totalCount = action.payload.length;
            state.status = 'ready';
        }).addCase(searchParentElementsThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Search Elements By ParentId
        */
        builder.addCase(findElementsByParentIdThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(findElementsByParentIdThunk.fulfilled, (state: ElementState, action: PayloadAction<Element[]>) => {
            state.elements = action.payload;
            state.totalCount = action.payload.length;
            state.status = 'ready';
        }).addCase(findElementsByParentIdThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
             Search Elements
        */
        builder.addCase(searchElementsThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(searchElementsThunk.fulfilled, (state: ElementState, action: PayloadAction<SearchResponse<Element>>) => {
            state.elements = action.payload.data;
            state.totalCount = action.payload.totalCount;
            state.status = 'ready';
        }).addCase(searchElementsThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });

        /*
            Update Element
        */
        builder.addCase(updateElementThunk.pending, (state: ElementState) => {
            state.status = 'loading';
        }).addCase(updateElementThunk.fulfilled, (state: ElementState, action: PayloadAction<Element>) => {
            updateElement(state, action);
        }).addCase(updateElementThunk.rejected, (state: ElementState) => {
            state.status = 'error';
        });
    }
});

const { changeElementSearcher, changeElementAction, changeFilterSkip, changeFilterStatus, changeFilterTake, changeFilterText, selectElement } = elementSlice.actions;

const getElementAction = (state: RootState) => state.element.elementAction;
const getElementFilter = (state: RootState) => state.element.filter;
const getElementSearcher = (state: RootState) => state.element.elementSearcher;
const getElementSelected = (state: RootState) => state.element.elementSelected;
const getFiles = (state: RootState) => state.element.elements.filter(element => element.fileUrl);
const getFolders = (state: RootState) => state.element.elements.filter(element => !element.fileUrl);
const getStatus = (state: RootState) => state.element.status;
const getTotalCount = (state: RootState) => state.element.totalCount;

export {
    changeElementSearcher,
    changeElementAction,
    changeFilterSkip,
    changeFilterStatus,
    changeFilterTake,
    changeFilterText,
    selectElement,

    getElementAction,
    getElementFilter,
    getElementSearcher,
    getElementSelected,
    getFiles,
    getFolders,
    getStatus,
    getTotalCount
}

export default elementSlice.reducer;