import { NotArrayError } from "src/app/errors/NotArrayError";
import { UserLog } from "./UserLog";

export class Log {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        readonly ip: string,
        readonly userLog: UserLog
    ) { }

    public static fromJson(json: any): Log {
        return new Log(
            json['id'],
            json['createdAt'],
            json['ip'],
            UserLog.fromJson(json['userLog'])
        );
    }

    public static fromJsonArray(jsonArray: any): Log[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}