import { NotArrayError } from "src/app/errors/NotArrayError";
import { User } from "src/features/user/domain/entities/User";

export class Rsvp {
    
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        readonly documentId: string,
        readonly user: User
    ) {}

    public static fromJson(json: any): Rsvp {
        return new Rsvp(
            json['id'],
            json['createdAt'],
            json['documentId'],
            User.fromJson(json['user']),
        );
    }

    public static fromJsonArray(jsonArray: any): Rsvp[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}