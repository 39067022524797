export class Role {
    static readonly Admin = new Role('admin');

    static readonly User = new Role('user');

    static readonly SuperUser = new Role('super_user');

    constructor(public readonly value: string) { }

    static getValues(): Role[] {
        return [this.Admin, this.User, this.SuperUser];
    }

    static getStringValues(): string[] {
        return this.getValues().map(value => value.value);
    }

    static fromString(string: string): Role {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}