import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { SearchResponse } from '../../commons/Types';
import { Filters } from '../../commons/Entities';
import { Alert } from "../domain/entities/Alert";
import { createAlertThunk, searchAlertsThunk } from "./AlertThunk";

interface AlertState {
    alerts: Alert[];
    alertSelected?: Alert;
    filter: Filters;
    status: StateStatus;
}

export const initialState: AlertState = {
    alerts: [],
    alertSelected: undefined,
    filter: {
        status: 'pending',
    },
    status: 'ready',
}

const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        changeFilter: (state: AlertState, action: PayloadAction<Filters>) => {
            state.filter = action.payload
        },

        changeFilterStatus: (state: AlertState, action: PayloadAction<string | null>) => {
            state.filter = {
                ...state.filter,
                status: action.payload
            };
        },
        clearAlerts: (state: AlertState, action: PayloadAction<void>) => {
            state.alerts = [];
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<AlertState>) => {
        /*
            Create Alert
        */
        builder.addCase(createAlertThunk.pending, (state: AlertState) => {
            state.status = 'loading';
        }).addCase(createAlertThunk.fulfilled, (state: AlertState, action: PayloadAction<Alert>) => {
            state.alerts.unshift(action.payload);
            state.alertSelected = undefined;
            state.status = 'ready';
        }).addCase(createAlertThunk.rejected, (state: AlertState) => {
            state.status = 'error';
        });


        /*
             Search Alerts
        */
        builder.addCase(searchAlertsThunk.pending, (state: AlertState) => {
            state.status = 'loading';
        }).addCase(searchAlertsThunk.fulfilled, (state: AlertState, action: PayloadAction<Alert[]>) => {
            state.alerts = action.payload;
        }).addCase(searchAlertsThunk.rejected, (state: AlertState) => {
            state.status = 'error';
        });
    }
});

const {
    changeFilter,
    changeFilterStatus,
    clearAlerts
} = alertSlice.actions;

const getAlertFilter = (state: RootState) => state.alert.filter;
const getAlerts = (state: RootState) => state.alert.alerts;
const getStatus = (state: RootState) => state.alert.status;

export {
    changeFilter,
    changeFilterStatus,
    clearAlerts,

    getAlertFilter,
    getAlerts,
    getStatus,
}

export default alertSlice.reducer;