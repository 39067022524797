import { alpha, Tooltip, IconButton, styled } from "@mui/material";
import { t } from "i18next";
import ElementsIcon from '@mui/icons-material/FolderTwoTone';
import { useNavigate } from "react-router-dom";

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
          margin-left: ${theme.spacing(1)};
          background: ${theme.colors.alpha.trueWhite[10]};
          color: ${theme.colors.alpha.trueWhite[70]};
          padding: 0;
          width: 42px;
          height: 42px;
          border-radius: 100%;
          transition: ${theme.transitions.create(['background', 'color'])};
      
          &.active,
          &:active,
          &:hover {
            background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
            color: ${theme.colors.alpha.trueWhite[100]};
          }
      `
);

const ElementsButton = () => {
    const navigate = useNavigate();
    const goToElements = () => {
        navigate('/elements/root');
    }

    return (
        <Tooltip arrow title={t('Element.Title')} onClick={goToElements} >
            <IconButtonPrimary color="primary" >
                <ElementsIcon />
            </IconButtonPrimary>
        </Tooltip>
    );
}

export default ElementsButton;