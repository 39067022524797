import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateAlertDto } from './AlertDto';
import { Filters } from '../../commons/Entities';
import { Alert } from '../domain/entities/Alert';
import { AlertService } from './AlertService';

export const createAlertThunk = createAsyncThunk(
    'alert/create',
    async (creatAlert: CreateAlertDto): Promise<Alert> =>
        AlertService.createAlertAsync(creatAlert)
);


export const searchAlertsThunk = createAsyncThunk(
    'alert/search',
    async (userId: string): Promise<Alert[]> => AlertService.searchAlertsAsync(userId)
);

