import { CreateElementDto, UpdateElementDto, CreateRsvpDto, ElementFilter, ElementsByParentIdDto, MoveElementDto } from './ElementDto';
import { Element } from '../domain/entities/Element';
import { getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
import { Rsvp } from '../domain/entities/Rsvp';
import { Status } from 'src/features/commons/Status';

export abstract class ElementService {

    public static async createRsvpAsync(createRsvp: CreateRsvpDto): Promise<Rsvp> {
        return Rsvp.fromJson(await postRequest('/rsvps', createRsvp));
    }

    public static async createElementAsync(createElement: CreateElementDto): Promise<Element> {
        return Element.fromJson(await postRequest('/elements', createElement));
    }

    public static async deleteElementAsync(elementId: string): Promise<Element> {
        return Element.fromJson(await patchRequest(`/elements`,
            {
                id: elementId, status: Status.Inactive.value
            }
        ));
    }

    public static async findElementsByParentIdAsync({ parentId, userId }: ElementsByParentIdDto): Promise<Element[]> {
        const url = userId ? `/users/${userId}/elements/${parentId}/elements` : `/elements/${parentId}/elements`;
        return Element.fromJsonArray(await getRequest(url));
    }

    public static async findElementByIdAsync(elementId: string): Promise<Element> {
        return Element.fromJson(await getRequest(`/elements/${elementId}`));
    }

    public static async searchParentElementsAsync(filters: ElementFilter): Promise<Element[]> {
        const url: string = filters.userId ? `/users/${filters.userId}/elements` : '/elements_parents';
        delete filters.userId;

        return Element.fromJsonArray(await getRequest(url, JSON.parse(JSON.stringify({
            ...filters,
            status: Status.Active.value
        }))));
    }

    public static async searchElementsAsync(filters: ElementFilter): Promise<SearchResponse<Element>> {
        const url: string = filters.userId ? `/users/${filters.userId}/elements` : '/elements';
        delete filters.userId;

        const response: SearchResponse<Element> = await getRequest(url, JSON.parse(JSON.stringify({
            ...filters,
            status: Status.Active.value
        })), {}, true);
        return {
            data: Element.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }

    public static async updateElementAsync(updateElement: UpdateElementDto): Promise<Element> {
        return Element.fromJson(await patchRequest('/elements', updateElement));
    }


    public static async moveElementAsync(moveElement: MoveElementDto): Promise<Element> {
        return Element.fromJson(await patchRequest('/elements/move', {
            elementId: moveElement.elementId,
            parentId: moveElement.parentId
        }));
    }
}