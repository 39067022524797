import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../domain/entities/User';
import { CreateUserDto, UpdateUserDto } from './UserDto';
import { UserService } from './UserService';
import { SearchResponse } from '../../commons/Types';
import { Filters } from '../../commons/Entities';

export const createUserThunk = createAsyncThunk(
    'user/create',
    async (createUser: CreateUserDto): Promise<User> =>
        UserService.createUserAsync(createUser)
);

export const deleteUserThunk = createAsyncThunk(
    'user/delete',
    async (userId: string) => UserService.deleteUserAsync(userId)
);

export const searchUsersThunk = createAsyncThunk(
    'user/search',
    async (filters: Filters): Promise<SearchResponse<User>> => UserService.searchUsersAsync(filters)
);

export const updateUserThunk = createAsyncThunk(
    'user/update',
    async (updateUser: UpdateUserDto): Promise<User> =>
        UserService.updateUserAsync(updateUser)
);

export const findUserByIdThunk = createAsyncThunk(
    'user/find_by_id',
    async (userId: string): Promise<User> => UserService.findUserByIdAsync(userId)
);