import { AlertStatus } from "./AlertStatus";

export class Alert {
  constructor(
    readonly id: string,
    public description: string,
    public createdAt: Date,
    public status: AlertStatus,
    public title: string,
    public responsedAt?: Date,
  ) { }

  static fromJson(json: any): Alert {
    return new Alert(
      json['id'],
      json['description'],
      new Date(json['createdAt']),
      AlertStatus.fromString(json['status']),
      json['title'],
      json['responsedAt'],
    )
  }

  static fromJsonArray(jsonArray: any): Alert[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map(json => this.fromJson(json));
    }

    throw new Error('body is not an array');
  }
}
