import { alpha, Tooltip, IconButton, styled } from "@mui/material";
import { t } from "i18next";
import PersonIcon from '@mui/icons-material/Person';
import { NavigationRoutes } from "src/app/navigation/NavigationRoutes";
import { useNavigate } from "react-router-dom";

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
      margin-left: ${theme.spacing(1)};
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]};
      padding: 0;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      transition: ${theme.transitions.create(['background', 'color'])};
  
      &.active,
      &:active,
      &:hover {
        background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
        color: ${theme.colors.alpha.trueWhite[100]};
      }
  `
);

const UsersButton = () => {
    const navigate = useNavigate();
    const goToUsers = () => {
        navigate(NavigationRoutes.Users);
    }

    return (
        <Tooltip arrow title={t('Users.Title')} onClick={goToUsers} >
            <IconButtonPrimary color="primary" >
                <PersonIcon />
            </IconButtonPrimary>
        </Tooltip>
    );
}

export default UsersButton;