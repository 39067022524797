import { Box, alpha, Typography, styled } from "@mui/material";
import { FC, Fragment } from "react";
import { Element } from "../../domain/entities/Element";
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import FolderIcon from '@mui/icons-material/FolderOpenTwoTone';
import FileIcon from '@mui/icons-material/InsertDriveFileTwoTone';

interface SearchResultItemProps {
    readonly element: Element;
    readonly onClickElement: (element: Element) => void;
}

const ListButton = styled(Box)(
    ({ theme }) => `
        background-color: transparent;
        color:  ${theme.colors.alpha.black[100]};
        transition: ${theme.transitions.create(['all'])};
        border: ${theme.colors.alpha.black[10]} solid 1px;
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(1)};
        margin: ${theme.spacing(1, 0)};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.alpha.black[50]};
          transition: ${theme.transitions.create(['all'])};
        }
  
        &:hover {
          background-color: ${alpha(theme.colors.primary.main, 0.08)};
          color:  ${theme.colors.primary.main};
          border-color: ${alpha(theme.colors.primary.main, 0.3)};
  
          & > div > .MuiSvgIcon-root {
            color:  ${theme.colors.primary.main};
          }
        }
  `
  );

const SearchResultItem: FC<SearchResultItemProps> = ({element, onClickElement}) => {

    const Icon = () => {
        if (element.fileUrl) {
            return (
                <FileIcon
                  sx={{
                    mr: 1
                  }}
                  fontSize="small"
                />
            )
        }

        return (
            <FolderIcon
                  sx={{
                    mr: 1
                  }}
                  fontSize="small"
                />
        )
    }

    return (
        <Fragment key={element.id}>
            <ListButton onClick={() => onClickElement(element)}>
              <Box display="flex" alignItems="flex-start">
                <Icon />
                <Typography>{element.name}</Typography>
              </Box>
              <ArrowRightIcon fontSize="small" />
            </ListButton>
        </Fragment>
    )
}

export default SearchResultItem;