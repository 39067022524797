import { useContext } from 'react';

import { Box, Card, IconButton, Typography, alpha, styled } from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

import Logo from './Logo';
import ElementSearcher from '../../../../features/element/ui/element_searcher/index';
import useAuth from 'src/app/hooks/useAuth';
import { Role } from 'src/features/user/domain/entities/Role';
import { useAppSelector } from 'src/config/hooks';
import { getPartnerSelected } from 'src/features/partner/data/PartnerSlice';
import { Partner } from 'src/features/partner/domain/entities/Partner';

const HeaderWrapper = styled(Card)(
	({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
	({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

const BoxLogoWrapper = styled(Box)(
	({ theme }) => `
  margin-right: ${theme.spacing(6)};  
`
);

function Header() {
	const { user } = useAuth();
	const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
	const partner: Partner = useAppSelector(getPartnerSelected);

	return (
		<HeaderWrapper>
			<Box display="flex" flexDirection={'row'} alignItems="left" paddingRight={2}>
				<Box>
					<Logo />
				</Box>
				<Box color={'red'} display="flex" flexDirection={'column'} alignItems="left">
					<Box display="flex" flexDirection={'row'} >
						<Typography paddingLeft={1} variant="h4" noWrap color={'white'}>
							GESTIÓN AUTOMÁTICA DE DOCUMENTACIÓN
						</Typography>
						<Typography paddingLeft={1} variant="h4" noWrap fontWeight={400} color={'white'}>
							v.2.0.0
						</Typography>
					</Box>
					<Box>
						<Typography paddingLeft={1} variant="h4" noWrap color={'white'}>
							{
								partner?.name.toUpperCase()
							}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				component="span"
				sx={{
					display: { xs: 'none', sm: 'inline-block' },
					width: '45%'
				}}
			>
				<ElementSearcher />
			</Box>
			<Box display="flex" alignItems="center">
				{
					user.role === Role.Admin.value
						? <HeaderButtons />
						: <></>
				}
				<HeaderUserbox />
			</Box>
		</HeaderWrapper>
	);
}

export default Header;
